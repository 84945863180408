import {useTranslation} from "react-i18next";
import {Site, siteArrayToMap} from "../utils/SitesApi";
import {StockQuantitiesEcomComponent} from "./StockQuantitiesEcomComponent";
import {StockQuantitiesTable} from "./StockQuantitiesTable";

export interface StockQuantitiesTableProps {
    stockQuantities: any,
    sites: Site[]
}

export const StockQuantitiesComponent = (props: StockQuantitiesTableProps) => {
    const {t} = useTranslation()
    const qtyMap = props.stockQuantities
    const sitesMap = siteArrayToMap(props.sites)

    if (qtyMap === undefined) {
        return null
    } else if (Object.keys(qtyMap).length === 0) {
        return <p>{t("StockQuantities.noResults")}</p>
    } else if (!qtyMap.hasOwnProperty("locationIdToLocationQuantity")) {
        return <StockQuantitiesEcomComponent stockQuantities={props.stockQuantities} sites={props.sites}/>
    }

    return (
        <StockQuantitiesTable quantityMap={qtyMap} sitesMap={sitesMap}/>
    )
}
