import {t} from "i18next";

export type GraphData = {
    displayName: string,
    jobTitle: string,
    mail: string,
    businessPhones: string[],
    officeLocation: string
};

const ProfileRow: React.FC<{k: string, v?: string}> = ({k, v}) => {
    return (
        <tr>
            <td>{k}</td>
            <td>{v}</td>
        </tr>
    )
}

export const ProfileData: React.FC<{graphData: GraphData}> = ({graphData}) => {
    return (
        <table>
            <tbody>
            <ProfileRow k={t("ProfileData.name")} v={graphData.displayName}/>
            <ProfileRow k={t("ProfileData.jobTitle")} v={graphData.jobTitle}/>
            <ProfileRow k={t("ProfileData.email")} v={graphData.mail}/>
            <ProfileRow k={t("ProfileData.phone")} v={graphData.businessPhones ? graphData.businessPhones[0] : ""}/>
            <ProfileRow k={t("ProfileData.office")} v={graphData.officeLocation}/>
            </tbody>
        </table>
    );
};