import {ChangeEvent} from "react";
import {Select} from "@s-group/design-system-components";
import {TFunction} from "i18next";
import {useTranslation} from "react-i18next";

export enum Chain {
    SO = "SO",
    PR = "PR",
    SM = "SM",
    SA = "SA",
    AL = "AL",
    AB = "AB",
    TR = "TR",
    SR = "SR",
}

export const stringChainToEnum = (chain: string) => {
    switch (chain) {
        case "SO": return Chain.SO
        case "PR": return Chain.PR
        case "SM": return Chain.SM
        case "SA": return Chain.SA
        case "AL": return Chain.AL
        case "AB": return Chain.AB
        case "TR": return Chain.TR
        case "SR": return Chain.SR
        default: return undefined
    }
}

const createOptions = (t: TFunction) => [
    { key: 'all', label: t('ChainSelect.stockQuantities'), value: 'all'},
    { key: 'so',  label: t('ChainSelect.so'),  value: 'SO'},
    { key: 'pr',  label: t('ChainSelect.pr'),  value: 'PR'},
    // These are currently not needed.
    /*
    { key: 'sm',  label: t('ChainSelect.sm'),  value: 'SM'},
    { key: 'sa',  label: t('ChainSelect.sa'),  value: 'SA'},
    { key: 'al',  label: t('ChainSelect.al'),  value: 'AL'},
    { key: 'ab',  label: t('ChainSelect.ab'),  value: 'AB'},
    { key: 'tr',  label: t('ChainSelect.tr'),  value: 'TR'},
    { key: 'sr',  label: t('ChainSelect.sr'),  value: 'SR'},
    */
]

interface ChainSelectProps {
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void,
}

export const ChainSelect = (props: ChainSelectProps) => {
    const { t } = useTranslation()
    const options = createOptions(t)
    return (
        <Select
            defaultValue={options[0].value}
            placeholder={"Chain"}
            style={{width: 200}}
            onChange={props.onChange}
        >
            {options.map((option) => (
                <option key={option.key} value={option.value}>
                    {option.label}
                </option>
            ))}
        </Select>
    )
}