export class BonusBuyUtils {

    static getSiteIdsString(siteIds: string[], sitesMap: any, delim: string): string {
        return siteIds.map((siteId: string) => {
            const site = sitesMap?.get(siteId)
            return site ? `${siteId}: ${site.siteName}` : siteId
        }).join(delim)
    }

    static getMaterialGroupsString(materialGroups: string[], delim: string): string {
        return materialGroups.join(delim);
    }

    static getBrandsString(brands: string[], delim: string): string {
        if(brands !== null && brands.length > 0) {
            return brands.join(delim);
        }
        return "";
    }

    static getItemsString(items: any, delim: string): string {
        return items.map((item: { sokId: any; unit: any; }) => `${item.sokId} (${item.unit})`)
            .join(delim);
    }

    static getReceiveItemIndex(index: number, totalLength: number): string {
        return totalLength > 1  ? ` #${index}` : ""
    }
}