import {useTranslation} from "react-i18next";
import {useAccount, useMsal} from "@azure/msal-react";
import {useCallback, useEffect, useState} from "react";
import {appRoles, backendUrl, getAxiosConfig} from "../config";
import {useParams} from "react-router-dom";
import axios, {AxiosError} from "axios";
import axiosRetry from "axios-retry";
import {StockQuantitiesComponent} from "./StockQuantitiesComponent";
import {LoadingAnimation} from "./LoadingAnimation";
import {isValidSokIdOrEan, ProductSearchInput} from "./ProductSearchInput";
import {GenericSearchResult} from "../domain/GenericSearchResult";
import {userHasRole} from "../utils/AuthUtil";
import {getSites, Site} from "../utils/SitesApi";
import {Chain, ChainSelect, stringChainToEnum} from "./ChainSelect";
import {getAccessToken, getAllBySokIdAndEan, handleSearchError} from "../utils/SearchFormUtil";
import {PreviousSearchList} from "./PreviousSearchList";
import {handleSetSearchToLocalStorage} from "../utils/LocalStorageUtil";
import MultipleResults from "./MultipleResults";
import { MultipleSearchResults } from "../domain/MultipleSearchResults";

interface StockQuantitiesUrlParams {
    sokId?: string
}

export const StockQuantitiesSearchForm = () => {
    const {t} = useTranslation()
    const {instance, accounts} = useMsal();
    const account = useAccount(accounts[0] || {});
    const urlParams = useParams<StockQuantitiesUrlParams>()
    const hasExtendedRole = userHasRole(appRoles.ExtendedUser, account)
    const [multipleSearchResults, setMultipleSearchResults] = useState<MultipleSearchResults | null>(null);  
    const [selectedChain, setSelectedChain] = useState<Chain>()
    const [searchResult, setSearchResult] = useState<GenericSearchResult>({inProgress: false});
    const [accessToken, setAccessToken] = useState("");
    const [sites, setSites] = useState<Site[]>([])

    // set retry config
    axiosRetry(axios, {retries: 3, retryDelay: (count) => count * 1000})

    const handleRivSearchError = (error: Error | AxiosError) => {
        handleSearchError(error, res => setSearchResult(res))
    }

    const searchStockQuantitiesBySokId = useCallback((sokId: string, token: string, ean: string | null, chain?: Chain) => {
        let path = "/service/retail-inventory-visibility-v2/productStockQuantities/" + sokId
        let postData = null

        console.log(chain)
        if (chain) {
            path = "/service/retail-inventory-visibility-v2/productStockQuantities/ecom?chain=" + chain +
                "&includeFilteringLogs=true"
            postData = JSON.stringify({sokIds: [sokId]})
        }
        axios.post(backendUrl + path, postData, getAxiosConfig(token))
            .then(response => {
                const data = response.data
                const res: GenericSearchResult = {
                    inProgress: false,
                    statusCode: response.status,
                    error: data.message,
                    data: data,
                    sokId: sokId
                }
                sessionStorage.setItem("latestSokId", sokId)
                window.history.replaceState({}, "", "/stock-quantities/" + sokId)
                setSearchResult(res)
                handleSetSearchToLocalStorage(res, ean, sokId)
            })
            .catch(handleRivSearchError)
    }, [])

    const search = useCallback((searchTerm: string, token: string, chain?: Chain) => {
        if (!searchTerm || !isValidSokIdOrEan(searchTerm)) {
            return
        }
        setSearchResult({inProgress: true})
        setMultipleSearchResults(null)
        searchTerm = searchTerm.trim()

        getAllBySokIdAndEan(searchTerm, token, "/stock-quantities/")  
        .then(result => {
            const numberOfResults = result.getNumberOfResults()
            if (numberOfResults === 0) {   
                setSearchResult({inProgress: false, statusCode: 404})             
            } else if (numberOfResults === 1) {
                const searchResult = result.getOneResultOrNull();
                if (searchResult !== null) {
                    searchStockQuantitiesBySokId(searchResult.sokId!, token, searchResult.data.ean, chain)
                } 
            } else {
                setMultipleSearchResults(result)
                setSearchResult({inProgress: false})
            }
        })
        .catch(error => {
            console.error(error)
            handleRivSearchError(error)
        })
    }, [searchStockQuantitiesBySokId])

    useEffect(() => {
        getAccessToken(account, instance).then(token => {
            setAccessToken(token)
            getSites(token).then(response => setSites(response))
            if (urlParams.sokId) {
                searchStockQuantitiesBySokId(urlParams.sokId, token, null)
            }
        })
    }, [instance, account, urlParams, searchStockQuantitiesBySokId]);

    // RouteGuard makes sure this does not happen in the app, but this is needed for component tests.
    if (!hasExtendedRole) {
        return <p>{t("PermissionDenied.genericMessage")}</p>
    }
    return (
        <div>
            <h1>{t("StockQuantities.title")}</h1>
            <p>{t("StockQuantities.helpText")}</p>
            <PreviousSearchList site={"/stock-quantities/"} />
            <ChainSelect onChange={(event) => {
                setSelectedChain(stringChainToEnum(event.currentTarget.value))
            }}/>
            <ProductSearchInput
                initialValue={urlParams ? urlParams.sokId : ""}
                onSearch={(value) => {
                    search(value, accessToken, selectedChain)
                }}
                onKeyPress={(event) => {
                    if (event.key === "Enter") {
                        const target: any = event.target
                        const text: string = target.value
                        search(text, accessToken, selectedChain)
                    }
                }}
            />
            <br/><br/>
            
            {multipleSearchResults ?
                <MultipleResults results={multipleSearchResults} site={"/stock-quantities/"} />
                :
                <>
                    {searchResult.inProgress && <LoadingAnimation/>}

                    {searchResult.statusCode && searchResult.statusCode !== 200 && sites.length > 0 ?
                        <RivSearchErrorMessage searchResult={searchResult}/>
                        :
                        <StockQuantitiesComponent stockQuantities={searchResult.data} sites={sites}/>
                    }
                </>
            }
            <br/>

        </div>
    );
};

interface RivSearchErrorMessageProps {
    searchResult: GenericSearchResult
}

const RivSearchErrorMessage = (props: RivSearchErrorMessageProps) => {
    const {t} = useTranslation()
    const {searchResult} = props
    let message
    if (searchResult.statusCode === 401) {
        message = t("StockQuantities.searchErrorMessages.statusCode401")
    } // Axios does not handle the timeout from RIV well so this is a workaround for that
    else if (searchResult.statusCode === 404 || (searchResult.error && searchResult.error.startsWith("Error: timeout"))) {
        message = t("StockQuantities.searchErrorMessages.statusCode404")
    } else {
        message = t("StockQuantities.searchErrorMessages.statusCode5xx")
    }
    return <p>{message}</p>
}