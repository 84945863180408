import {useTranslation} from "react-i18next";
import {BonusBuyUtils} from "./BonusBuyUtils";
import {ScenarioTable} from "./ScenarioTable";

interface Scenario1TableProps {
    bonusBuys: any
    sitesMap: any
}

// Buy product for a price X
export const Scenario1Table = (props: Scenario1TableProps) => {
    const {t} = useTranslation()
    const {bonusBuys} = props

    if (!bonusBuys || bonusBuys.length === 0) {
        return null
    }

    return bonusBuys.map((bb: any, index: number) => {
        if (bb["sourceDeletedAt"] !== null) {
            return null
        }

        const bonusBuyMap = new Map<string, string | undefined>()
        bonusBuyMap.set(t("BonusBuys.id"), bb["bonusBuyId"])
        bonusBuyMap.set(t("BonusBuys.promotionId"), bb["promotionId"])
        bonusBuyMap.set(t("BonusBuys.description"), bb["bonusBuyDetails"]["description"])
        bonusBuyMap.set(t("BonusBuys.customerType"), bb["bonusBuyDetails"]["customerType"])
        bonusBuyMap.set(t("BonusBuys.validFrom"), bb["bonusBuyDetails"]["validFrom"])
        bonusBuyMap.set(t("BonusBuys.validTo"), bb["bonusBuyDetails"]["validTo"])
        bonusBuyMap.set(t("BonusBuys.sites"), BonusBuyUtils.getSiteIdsString(bb["bonusBuyDetails"]["siteIds"], props.sitesMap, "\n"))
        bonusBuyMap.set(t("BonusBuys.coupon"), bb["bonusBuyDetails"]["coupon"])
        bonusBuyMap.set(t("BonusBuys.limit"), bb["bonusBuyDetails"]["limit"])

        const receiveDetails = bb["bonusBuyDetails"]["receiveDetails"]
        receiveDetails.forEach((receiveItem: any, receiveIndex: number) => {
            const index = receiveIndex + 1;
            bonusBuyMap.set(
                t("BonusBuys.discountPrice") + BonusBuyUtils.getReceiveItemIndex(index, receiveDetails.length),
                `${receiveItem["discount"]} ${receiveItem["discountUnit"]}`);
            bonusBuyMap.set(
                t("BonusBuys.discountItems") + BonusBuyUtils.getReceiveItemIndex(index, receiveDetails.length),
                BonusBuyUtils.getItemsString(receiveItem["items"], "\n"));
            bonusBuyMap.set(
                t("BonusBuys.materialGroups") + BonusBuyUtils.getReceiveItemIndex(index, receiveDetails.length),
                receiveItem["materialGroups"]?.join("\n"));
        })

        return ScenarioTable({
            bonusBuysMap: bonusBuyMap,
            tableHeader: "BonusBuys.scenario1Description",
            id: "BonusBuysScenario1Table-" + index
        })
    })
}