import {Site} from "../utils/SitesApi";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {formatDateWithHyphens} from "../utils/DateUtils";

interface SitesTableProps {
    sites: Site[]
}

export const SitesTable = (props: SitesTableProps) => {
    const { t } = useTranslation()
    const { sites } = props
    const [sort, setSort] = useState("siteCode")
    if(!sites) {
        return null
    }
    if(sort) {
        sites.sort((a: any, b: any) => {
            // satoPlantNumber is in another object, so need to pass another object if that was selected
            const o1 = sort === "retailPlantId" ? a.meta : a
            const o2 = sort === "retailPlantId" ? b.meta : b
            return sortStringsEmptyStringsAtEnd(getFieldValueOrEmptyString(o1, sort), getFieldValueOrEmptyString(o2, sort))
        })
    }
    return (
        <div className={"table-wrapper"}>
            <table>
                <thead>
                <tr>
                    <th onClick={() => setSort("siteCode")}>{t("Common.id")}</th>
                    <th onClick={() => setSort("retailPlantId")}>{t("Sites.retailPlantID")}</th>
                    <th onClick={() => setSort("siteName")}>{t("Common.siteName")}</th>
                    <th onClick={() => setSort("businessOpeningDate")}>{t("Sites.opened")}</th>
                    <th onClick={() => setSort("businessClosingDate")}>{t("Sites.closed")}</th>
                    <th onClick={() => setSort("siteInMarttiUntilDate")}>{t("Sites.inMarttiUntilDate")}</th>
                </tr>
                </thead>
                <tbody>
                {
                    sites.map((p: any) => {
                        return <tr key={"site-" + p.siteCode}>
                            <td>{p.siteCode}</td>
                            <td>{p.meta && p.meta.retailPlantId ? p.meta.retailPlantId : ""}</td>
                            <td>{p.siteName}</td>
                            <td>{p.businessOpeningDate}</td>
                            <td>{p.businessClosingDate}</td>
                            <td>{formatDateWithHyphens(p.siteInMarttiUntilDate)}</td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    )
}

function sortStringsEmptyStringsAtEnd(a: string, b: string): number {
    if (a === '') return 1
    if (b === '') return -1
    return a.localeCompare(b)
}

function getFieldValueOrEmptyString(obj: any, field: string) {
    if (obj && obj[field]) {
        return obj[field].trim()
    }
    return ""
}