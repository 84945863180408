import {useTranslation} from "react-i18next";
import {useAccount, useMsal} from "@azure/msal-react";
import {useEffect, useState} from "react";
import {getAccessToken} from "../utils/SearchFormUtil";
import {getSites, Site} from "../utils/SitesApi";
import {SitesTable} from "./SitesTable";
import {LoadingAnimation} from "./LoadingAnimation";


export const Sites = () => {
    const { t } = useTranslation()
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [sites, setSites] = useState<Site[]>([])

    useEffect(() => {
        getAccessToken(account, instance).then(token => {
            getSites(token).then(response => setSites(response))
        })
    }, [instance, account]);

    return (
        <div>
            <h1>{t("Sites.title")}</h1>
            <p>{t("Sites.dataSourceDescription")}</p>
            {sites && sites.length > 0 ? <SitesTable sites={sites}/> : <LoadingAnimation />}
        </div>
    );
};