import {Route, RouteProps} from 'react-router-dom';
import React, {useState, useEffect} from "react";
import {useMsal} from "@azure/msal-react";
import {useTranslation} from "react-i18next";

interface IRouteGuardProps
{
    Component: any,
    allowedRoles: string[],
}

export function RouteGuard ({Component, allowedRoles, ...props}:
                                IRouteGuardProps & RouteProps
) {
    const { t } = useTranslation()
    const { instance } = useMsal();
    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
        if (instance) {
            let account = instance.getActiveAccount()

            if (account) {
                let claims = account.idTokenClaims as any
                if ("roles" in claims) {
                    let intersection = allowedRoles.filter(role => claims["roles"].includes(role));

                    if (intersection.length > 0) {
                        setIsAuthorized(true);
                    }
                }
            }
        }
    }, [instance, allowedRoles]);
    return (
        <>
            {
                isAuthorized
                    ?
                    <Route {...props} render={routeProps => <Component {...routeProps} />} />
                    :
                    <div>
                        <p>{t("PermissionDenied.genericMessage")}</p>
                        <p>{t("PermissionDenied.instructions")} <a href="https://sryhma.service-now.com/sp?id=sc_cat_item&sys_id=9ac79de21b9b4910505deb11b24bcb73">{t("PermissionDenied.link")}</a></p>
                        <p>{t("PermissionDenied.adGroupName")} 10000000gg_AD_AapaBrowser_ExtendedUsers</p>
                        <br></br>
                        <p>{t("PermissionDenied.note")}</p>
                        <br></br>
                        <p>{t("PermissionDenied.adGroupOwner")} marja.mikkonen@sok.fi</p>
                    </div>
            }
        </>
    );
}

