import React from 'react';
import { getProductName } from './FullProduct';
import { useTranslation } from 'react-i18next';
import { MultipleSearchResults } from '../domain/MultipleSearchResults';

interface MultipleResultsProps {
    results: MultipleSearchResults;
    site: string;
}

export const  MultipleResults = (props: MultipleResultsProps) => {
    const { t, i18n } = useTranslation()
    const lang = i18n.language
    const resultsArray = Object.entries(props.results);


    return (
        <div>
            <h2>{(t("MultipleResults.title"))}</h2>
            <p>{(t("MultipleResults.helpText"))}</p>
            <div className={"multiple-results-container"}>
            { resultsArray.map(([key, value], index) => {
                if (value !== null) {
                    return (
                        <div key={index}>
                            <span>{key}: </span>
                            {key === "eanEE" ? (
                                <a href={`${props.site}${value.sokId}`}>{getProductName(value.data, lang, "EE")}</a>
                            ) : (
                                <a href={`${props.site}${value.sokId}`}>{getProductName(value.data, lang, "FI")}</a>
                            )}
                        </div>
                    );
                }
                return null;
            })}
            </div>
        </div>
    );
};

export default MultipleResults;