import moment from 'moment';
import {getTimestampTimeZone, TimestampTimeZone} from "./LocalStorageUtil";

/**
 * Convert datetime string to ISO8601 style string, but without T,
 * at UTC or local timezone depending on the requested timezone setting in local storage.
 * In case of error, returns the original string. Defaults to UTC.
 */
export const formatTimestamp = (ts: string) => {
    if (!ts || ts === "1970-01-01T00:00:00.000+00:00") {
        return ""
    }
    if (!moment(ts, moment.ISO_8601).isValid()) {
        return ts
    }

    const tsTimeZone = getTimestampTimeZone()
    if (tsTimeZone === TimestampTimeZone.UTC) {
        return moment(ts).utc().format('YYYY-MM-DD HH:mm:ss[Z]')
    } else if (tsTimeZone === TimestampTimeZone.LOCAL) {
        return moment(ts).local().format('YYYY-MM-DD HH:mm:ssZ')
    } else {
        return ts
    }
}

/**
 * Ensure that the date is in the format YYYY-MM-DD if it does not already have hyphens
 */
export const formatDateWithHyphens = (date: string | null): string => {
    if (date && date.length === 8) {
        return `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`
    }
    return date || ""
}