import { GenericSearchResult } from "../domain/GenericSearchResult";

export enum Storage {
    PREVIOUS_SEARCHES = "previousSearches",
    TIMESTAMP_TIME_ZONE_FORMAT = "timestampTimeZoneFormat"
}

export interface SearchObject {
    sokId: string
    ean: string | null
}

export const setSearchToLocalStorage = (searchObject: SearchObject) => {

    let previousSearches = getPreviousSearches()

    const existingIndex = previousSearches.findIndex((item: SearchObject) => item.sokId === searchObject.sokId);

    if (existingIndex !== -1) {
        const existingObject = previousSearches[existingIndex];
        if (!existingObject.ean && searchObject.ean) {
            previousSearches[existingIndex].ean = searchObject.ean;
        }
    } else {
        previousSearches.unshift(searchObject);
    }

    if (previousSearches.length > 5) {
        previousSearches = previousSearches.slice(0, 5);
    }

    localStorage.setItem('previousSearches', JSON.stringify(previousSearches));
    window.dispatchEvent(new Event("storage"));
}

export const getPreviousSearches = () => {
    return JSON.parse(localStorage.getItem(Storage.PREVIOUS_SEARCHES) || "[]");
};

export const handleSetSearchToLocalStorage = (res: GenericSearchResult, ean: string | null, sokId: string) => {
    if(res.statusCode === 200 && !res.error){
        const searchInstance: SearchObject = {
            sokId: sokId,
            ean: ean
        };
        setSearchToLocalStorage(searchInstance);
    }
}

export enum TimestampTimeZone {
    UTC = "utc",
    LOCAL = "local"
}

export const DEFAULT_TS_TIME_ZONE_FORMAT = TimestampTimeZone.LOCAL;

export const getTimestampTimeZone = () => {
    return localStorage.getItem(Storage.TIMESTAMP_TIME_ZONE_FORMAT) || DEFAULT_TS_TIME_ZONE_FORMAT;
}

export const setTimestampTimeZone = (timeZone: TimestampTimeZone) => {
    localStorage.setItem(Storage.TIMESTAMP_TIME_ZONE_FORMAT, timeZone);
}