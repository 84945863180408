import { GenericSearchResult } from "./GenericSearchResult";

export class MultipleSearchResults {
    sokId: GenericSearchResult | null;
    ean: GenericSearchResult | null;
    eanEE: GenericSearchResult | null;

    constructor(sokId: GenericSearchResult | null, ean: GenericSearchResult | null, eanEE: GenericSearchResult | null) {
        this.sokId = sokId;
        this.ean = ean;
        this.eanEE = eanEE;
    }

    getNumberOfResults(): number {
        return [this.sokId, this.ean, this.eanEE].filter(result => result !== null).length;
    }

    getOneResultOrNull(): GenericSearchResult | null {
        const nonNullResults = [this.sokId, this.ean, this.eanEE].filter(result => result !== null);
        if (nonNullResults.length === 1) {
            return nonNullResults[0]!;
        } else {
            return null;
        }
    }
}
