import {ChangeEvent, useEffect, useState} from "react";

// Msal imports
import {MsalAuthenticationTemplate, useMsal} from "@azure/msal-react";
import {InteractionStatus, InteractionType, InteractionRequiredAuthError, AccountInfo} from "@azure/msal-browser";
import {loginRequest} from "../config";

// App imports
import {ProfileData, GraphData} from "../ui-components/ProfileData";
import {Loading} from "../ui-components/Loading";
import {ErrorComponent} from "../ui-components/ErrorComponent";
import {callMsGraph} from "../utils/MsGraphApiCall";
import {useTranslation} from "react-i18next";
import {Button, ToggleChip, Select} from "@s-group/design-system-components";
import {t} from "i18next";
import {getTimestampTimeZone, setTimestampTimeZone, TimestampTimeZone} from "../utils/LocalStorageUtil";

export const KEY_HEADING_NAVIGATOR_DISABLED = "ffHeadingNavigatorDisabled";

const ProfileContent = () => {
    const {i18n} = useTranslation()
    const {instance, inProgress} = useMsal();
    const [graphData, setGraphData] = useState<null | GraphData>(null);

    const [timestampFormat, setTimestampFormat] =
        useState(getTimestampTimeZone())
    const headingNavigatorDisabled = localStorage.getItem(KEY_HEADING_NAVIGATOR_DISABLED) === "true"

    useEffect(() => {
        if (!graphData && inProgress === InteractionStatus.None) {
            callMsGraph().then(response => setGraphData(response)).catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: instance.getActiveAccount() as AccountInfo
                    });
                }
            });
        }
    }, [inProgress, graphData, instance]);

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang)
        localStorage.setItem('language', lang)
    }

    const toggleHeadingNavigator = () => {
        let newState = localStorage.getItem(KEY_HEADING_NAVIGATOR_DISABLED) !== "true"
        localStorage.setItem(KEY_HEADING_NAVIGATOR_DISABLED, newState.toString())
        window.location.reload()
    }

    const changeTimestampFormat = (value: string) => {
        setTimestampFormat(value)
        setTimestampTimeZone(value as TimestampTimeZone)
    }

    return (
        <div>
            <h1>{t("Profile.information")}</h1>
            {graphData ? <ProfileData graphData={graphData}/> : <Loading/>}

            <h2>{t("Profile.settings")}</h2>
            <br/>

            <h3>{t("Profile.timestampTimeZone")}</h3>
            <Select value={timestampFormat}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => changeTimestampFormat(event.target.value)}
                    sizing="xsmall">
                <option value={TimestampTimeZone.UTC}>{t("Profile.timeZoneUtc")}</option>
                <option value={TimestampTimeZone.LOCAL}>{t("Profile.timeZoneLocal")}</option>
            </Select>
            <br/><br/>

            <h3>{t("Profile.navigation")}</h3>
            <ToggleChip variant="filled" checked={!headingNavigatorDisabled} onChange={toggleHeadingNavigator}>
                {t("Profile.headingNavigator")}
            </ToggleChip>
            <br/><br/>

            <h2>{t("Profile.language")}</h2>
            <br/>
            <Button onClick={() => changeLanguage("en")}>{t("Profile.english")}</Button>
            <Button onClick={() => changeLanguage("fi")}>{t("Profile.finnish")}</Button>
        </div>
    );
};

export function Profile() {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={Loading}
        >
            <ProfileContent/>
        </MsalAuthenticationTemplate>
    )
}