import {AccountInfo} from "@azure/msal-browser";

export function userHasRole(role: string, account: AccountInfo | null): boolean {
    if (account) {
        // Sonar thinks this cast is redundant but code will not compile without it
        let claims = account.idTokenClaims as any // NOSONAR
        if ("roles" in claims) {
            return claims["roles"].indexOf(role) >= 0
        }
    }
    return false
}