import axios, {AxiosError, AxiosResponse} from "axios";
import {appScope, backendUrl, getAxiosConfig, loginRequest} from "../config";
import {GenericSearchResult} from "../domain/GenericSearchResult";
import {AccountInfo, IPublicClientApplication} from "@azure/msal-browser";
import {SearchType} from "../ui-components/SearchTypeSelect";
import { MultipleSearchResults } from "../domain/MultipleSearchResults";

export interface SokIdUrlParam {
    sokId?: string
}

export const getAccessToken = (
    account: AccountInfo | null,
    instance: IPublicClientApplication,
) => {
    return new Promise<string>((resolve, reject) => {
        if (account) {
            instance.acquireTokenSilent({
                scopes: [ appScope ],
                redirectUri: ""
            }).then((response) => {
                if(response) {
                    console.log(response.accessToken)
                    resolve(response.accessToken)
                }
            }).catch(error => {
                console.log(error)
                reject(error)
                instance.loginRedirect(loginRequest)
            });
        }
    })
}

export const searchByEanCode = (
    searchTerm: string,
    searchType: SearchType,
    token: string
) => {
    searchTerm = searchTerm.trim()
    let url = backendUrl + "/service/retail-product-api-v3/products/search/findByEan?ean=" + searchTerm
    if (searchType === SearchType.EAN_EE) {
        url += "&targetMarket=EE"
    }

    return new Promise<GenericSearchResult | null>(((resolve, reject) => {
        axios.get(url, getAxiosConfig(token), )
        .then(response => {
            if (response && response.status === 404) {
                resolve(null);
            } else {
                const data = response.data as any
                const sokId = data ? data.id : null
                const res: GenericSearchResult = {
                    inProgress: false,
                    statusCode: response.status,
                    error: data.message,
                    data: data,
                    sokId: sokId
                }
                resolve(res)
            }
        })
        .catch(error => {
            console.error(error);
            reject(error); 
        });
    }))
}

const handleApiResponse = (response: AxiosResponse, sokId: string, redirectUrl?: string) => {
    const data = response.data as any
    const res: GenericSearchResult = {
        inProgress: false,
        statusCode: response.status,
        error: data.message,
        data: data,
        sokId: sokId
    }
    if (response.status === 200 && redirectUrl) {
        sessionStorage.setItem("latestSokId", sokId)
        window.history.replaceState({}, "", redirectUrl)
    }
    return res
}



export const searchFromRetailProductApiBySokIdUsingGet = (
    path: string,
    sokId: string,
    token: string,
    redirectUrl: string
) => {
    return new Promise<GenericSearchResult | null>(((resolve, reject) => {
        axios.get(backendUrl + "/service/retail-product-api-v3/" + path, getAxiosConfig(token))
            .then(response => {
                if(response && response.status === 404) {
                    resolve(null)
                } else {
                    resolve(handleApiResponse(response, sokId, redirectUrl))
                }
            })
            .catch(e => {
                reject(e)
            })
    }))
}

export const searchFromRetailProductApiBySokIdUsingPost = (
    path: string,
    sokId: string,
    token: string,
    redirectUrl: string,
    otherPayloadParams: any = {}
) => {
    // move this
    return new Promise<GenericSearchResult>((resolve, reject) => {
        const postData = JSON.stringify({ sokIds: [ sokId ], onlyActive: true, ...otherPayloadParams })
        axios.post(backendUrl + "/service/retail-product-api-v3/" + path, postData, getAxiosConfig(token))
            .then(response => {
                resolve(handleApiResponse(response, sokId, redirectUrl))
            })
            .catch(e => {
                console.log(e)
                reject(e)
            })
    })
}

export const searchBySokIdUsingPost = (
    path: string,
    sokId: string,
    token: string,
    redirectUrl: string,
) => {
    return new Promise<GenericSearchResult>((resolve, reject) => {
        const postData = JSON.stringify({ sokIds: [ sokId ], onlyActive: true })
        axios.post(backendUrl + "/service/retail-product-api-v3/" + path, postData, getAxiosConfig(token))
            .then(response => {
                resolve(handleApiResponse(response, sokId, redirectUrl))
            })
            .catch(e => {
                console.log(e)
                reject(e)
            })
    })
}

export const handleSearchError = (error: Error | AxiosError, onErrorHandled: (result: GenericSearchResult) => void) => {
    const res: GenericSearchResult = {
        inProgress: false,
        statusCode: -1,
        error: error.toString()
    }
    onErrorHandled(res)
}

export const getAttributeDetails = (token: string) => {
    return new Promise<Map<any, any>>((resolve, reject) => {
        axios.get(backendUrl + "/attributeDetails", getAxiosConfig(token))
            .then(response => {
                const data = response.data as any[]
                if (response.status === 200) {
                    const attrDetailsMap = new Map(data.map(obj => [obj.attributeId, obj]));
                    resolve(attrDetailsMap)
                }
                reject("HTTP status: " + response.status)
            })
            .catch((error: Error | AxiosError) => { console.log(error) })
    })
}

export const getNonEmptyColumns = (items: any[]) => {
    const nonEmpty = new Set<string>()
    items.forEach(item => {
        const keys = Object.keys(item)
        keys.forEach(k => {
            if(item[k]) {
                nonEmpty.add(k)
            }
        })
    })
    return nonEmpty
}

export const getAllBySokIdAndEan = (searchTerm: string, token: string, site: string): Promise<MultipleSearchResults> => {
    return new Promise<MultipleSearchResults>((resolve, reject) => {
        Promise.all([
            searchFromRetailProductApiBySokIdUsingGet("fullProducts/" + searchTerm, searchTerm, token, site + searchTerm),
            searchByEanCode(searchTerm, SearchType.EAN, token)
                .then(result => { 
                    if(result != null){
                        return searchFromRetailProductApiBySokIdUsingGet("fullProducts/" + result.sokId, result.sokId!, token, site + result.sokId); 
                    } else
                        return null;
                }),
            searchByEanCode(searchTerm, SearchType.EAN_EE, token)
                .then(result => { 
                    if(result!= null){
                        return searchFromRetailProductApiBySokIdUsingGet("fullProducts/" + result.sokId, result.sokId!, token, site + result.sokId); 
                    } else
                        return null;
                })
        ]).then(([sokId, ean, eanEE]) => {
            return resolve(new MultipleSearchResults(sokId, ean, eanEE));
        })
        .catch(error => {
            console.error(error);
            reject(error);
        })
    });
}
