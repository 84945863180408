import {useTranslation} from "react-i18next";
import {Fragment} from "react";

interface ScenarioTableProps {
    bonusBuysMap: Map<string, string | undefined>
    tableHeader: string | null
    id: string
}

// Buy product for a price X
export const ScenarioTable = (props: ScenarioTableProps) => {
    const {t} = useTranslation()

    if (!props.bonusBuysMap || props.bonusBuysMap.size === 0) {
        return null
    }

    const bonusBuyRow = (header: string | undefined, value: string | undefined): JSX.Element | null => {
        if (!header || !value) {
            return null
        }
        return <tr>
            <th>{header}</th>
            <td>{value}</td>
        </tr>
    }

    return (
        <table data-testid={props.id} className="bonus-buy-table" key={props.id}>
            <thead>
            {props.tableHeader ? 
                <tr>
                    <th colSpan={2}>{t(props.tableHeader)}</th>
                </tr>
                : null
            }
            </thead>
            <tbody>
            {Array.from(props.bonusBuysMap.entries()).map(([key, value], index) => (
                <Fragment key={"bonusBuyItem" + index}>
                    {bonusBuyRow(key, value)}
                </Fragment>
            ))}
            </tbody>
        </table>
    )
}
