import {DepositList} from "./DepositList";
import {useTranslation} from "react-i18next";
import {getNonEmptyColumns} from "../utils/SearchFormUtil";
import {allPricingUnitsEqual1} from "./StoreRetailPricesTable";
import {formatTimestamp} from "../utils/DateUtils";

export const ChainRetailPricesTable = (props: { prices: any }) => {
    const { t } = useTranslation()
    const { prices } = props
    if(!prices) {
        return null
    } else if (prices.length === 0) {
        return <p>{t("Prices.noPrices")}</p>
    }
    const nonEmptyCols = getNonEmptyColumns(prices)
    // Hide pricing unit if it is 1 in every row
    if (allPricingUnitsEqual1(prices)) {
        nonEmptyCols.delete("pricingUnit")
    }
    return (
        <div className={"table-wrapper"}>
            <table>
                <thead>
                <tr>
                    <th>{t("Common.chain")}</th>
                    <th>{t("Prices.amount")}</th>
                    <th>{t("Prices.currency")}</th>
                    <th>{t("Common.validFrom")}</th>
                    <th>{t("Common.validTo")}</th>
                    {/* Following words are quite long in Finnish and we want to use the HTML code for soft
                        hyphen &shy; and it needs to be escaped for React AND i18next library. */}
                    <th dangerouslySetInnerHTML={
                        {__html: t('Prices.salesUnitWithSoftHyphen', {interpolation: {escapeValue: false}})}
                    } />
                    {nonEmptyCols.has("pricingUnit") && <th dangerouslySetInnerHTML={
                        {__html: t('Prices.pricingUnitWithSoftHyphen', {interpolation: {escapeValue: false}})}
                    } />}
                    {nonEmptyCols.has("deposits") && <th>{t("Prices.deposits")}</th>}
                    <th>{t("Common.modifiedAt")}</th>
                </tr>
                </thead>
                <tbody>
                {
                    prices.map((p: any) => {
                        return <tr key={"chain-retail-price-" + p.id} title={"ID: " + p.id}>
                            <td>{p.salesOrg}</td>
                            <td>{p.amount}</td>
                            <td>{p.currency}</td>
                            <td>{p.validFrom}</td>
                            <td>{p.validTo}</td>
                            <td>{p.salesUnit}</td>
                            {nonEmptyCols.has("pricingUnit") && <td>{p.pricingUnit}</td>}
                            {nonEmptyCols.has("deposits") && <td><DepositList deposits={p.deposits}/></td>}
                            <td>{formatTimestamp(p.sourceModifiedAt)}</td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    )
}