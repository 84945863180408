import {Switch, Route, useHistory, Redirect} from "react-router-dom";

// MSAL imports
import {AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate} from "@azure/msal-react";
import {IPublicClientApplication} from "@azure/msal-browser";
import {CustomNavigationClient} from "./utils/NavigationClient";

import './App.css';

// App imports
import {appRoles} from "./config";
import {PageLayout} from "./ui-components/PageLayout";
import {DebugPage} from "./pages/DebugPage";
import {Profile} from "./pages/Profile";
import {LoginPage} from "./pages/LoginPage";
import {RouteGuard} from "./ui-components/RouteGuard"
import {RetailProductApiSearchForm} from "./ui-components/RetailProductApiSearchForm";
import {StockQuantitiesSearchForm} from "./ui-components/StockQuantitiesSearchForm";
import {AssortmentsSearchForm} from "./ui-components/AssortmentsSearchForm";
import {PricesSearchForm} from "./ui-components/PricesSearchForm";
import {Sites} from "./ui-components/Sites";
import axiosRetry from "axios-retry";
import axios from "axios";

type AppProps = {
    pca: IPublicClientApplication
};

function App({ pca }: AppProps) {
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const history = useHistory();
    const navigationClient = new CustomNavigationClient(history);
    pca.setNavigationClient(navigationClient);

    // set retry config
    axiosRetry(axios, { retries: 3, retryDelay: (count) => count * 1000 })
  
    return (
        <MsalProvider instance={pca}>
                <AuthenticatedTemplate>
                    <PageLayout>
                        <PagesAuthenticated />
                    </PageLayout>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <PageLayout>
                        <PagesUnauthenticated />
                    </PageLayout>
                </UnauthenticatedTemplate>
        </MsalProvider>
    );
}
  
function PagesAuthenticated() {

    return (
        <Switch>
            <Route exact={true} path="/product-api">
                <RetailProductApiSearchForm/>
            </Route>
            <Route exact={true} path="/product-api/:sokId">
                <RetailProductApiSearchForm/>
            </Route>
            <RouteGuard
                key="stock-quantities"
                exact={true} path="/stock-quantities"
                Component={StockQuantitiesSearchForm}
                allowedRoles={[appRoles.ExtendedUser]}
            />
            <RouteGuard
                key="stock-quantities-sok-id"
                exact={true} path="/stock-quantities/:sokId"
                Component={StockQuantitiesSearchForm}
                allowedRoles={[appRoles.ExtendedUser]}
            />
            <RouteGuard
                key="assortments"
                exact={true} path="/assortments"
                Component={AssortmentsSearchForm}
                allowedRoles={[appRoles.ExtendedUser]}
            />
            <RouteGuard
                key="assortments-sok-id"
                exact={true} path="/assortments/:sokId"
                Component={AssortmentsSearchForm}
                allowedRoles={[appRoles.ExtendedUser]}
            />
            <RouteGuard
                key="prices"
                exact={true} path="/prices"
                Component={PricesSearchForm}
                allowedRoles={[appRoles.ExtendedUser]}
            />
            <RouteGuard
                key="prices-sok-id"
                exact={true} path="/prices/:sokId"
                Component={PricesSearchForm}
                allowedRoles={[appRoles.ExtendedUser]}
            />
            <Route exact={true} path="/sites">
                <Sites/>
            </Route>
            <Route exact={true} path="/profile">
                <Profile />
            </Route>
            <Route exact={true} path="/debug">
                <DebugPage />
            </Route>
            <Route path="/">
                <Redirect to={"/product-api"} />
            </Route>
        </Switch>
    )
}

function PagesUnauthenticated() {
    return (
        <Switch>
            <Route exact={true} path="/">
                <LoginPage/>
            </Route>
            <Route path={"/*"}>
                <Redirect to={"/"} />
            </Route>
        </Switch>
    )
}

export default App;
