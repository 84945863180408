import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import App from './App';
import {createRoot} from "react-dom/client";
import {HeadingNavigator} from "./ui-components/HeadingNavigator";

// DS imports
import '@s-group/design-system-tokens/web/assets/fonts/SBonusUX.css';
import '@s-group/design-system-tokens/web/assets/fonts/SBonusDisplay.css';
import '@s-group/design-system-tokens/web/tokens/font.css';
import * as theme from '@s-group/design-system-tokens/web/tokens/sbrand/theme';
import {BrandWrapper} from "@s-group/design-system-components";

// MSAL imports
import {PublicClientApplication, EventType, EventMessage, AuthenticationResult} from "@azure/msal-browser";
import {msalConfig} from "./config";

// i18next config
import './i18n/config';

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <Router>
        <BrandWrapper theme={theme}>
            <App pca={msalInstance} />
            <HeadingNavigator/>
        </BrandWrapper>
    </Router>
);
