import React, {useEffect, useState} from "react";
import {useMsal} from "@azure/msal-react";
import {Button} from "@s-group/design-system-components";
import {IconUserProfile} from "@s-group/design-system-icons"
import {useHistory} from "react-router-dom";

const ProfileButton = () => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount()
    const [name, setName] = useState("");
    const history = useHistory();

    useEffect(() => {
        if (account && account.name) {
            setName(account.name);
        }
    }, [instance, account]);

    if (name) {
        return (
            <Button
                id={"profile-button"}
                variant={"plain"} 
                color={"neutral"} 
                sizing={"small"}
                icon={<IconUserProfile/>}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                    history.push("/profile"); event.currentTarget.blur()
                }}
            >{name}</Button>
        );
    }
    return null;
}

export default ProfileButton;
