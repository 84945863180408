import {Collapsible, CollapsibleGroup} from "@s-group/design-system-components";
import {useTranslation} from "react-i18next";
import {TFunction} from "i18next";

export interface FullProductProps {
    source: "RETAIL_PRODUCT_API" | "RETAIL_PRODUCT_SEARCH"
    result: any,
    showNullFields: boolean,
    showJsonFieldNames: boolean,
    openAllAccordions: boolean,
    attributeDetails?: Map<any, any>
}

export const FullProduct = (props: FullProductProps) => { // NOSONAR
    const { source, result, showNullFields, showJsonFieldNames, openAllAccordions } = props
    const { t, i18n } = useTranslation()

    // TODO: Remove the source check from here when the other view is implemented.
    // There's only small differences in the structure, so it is smart to re-use most of this component.
    if (!result || source === "RETAIL_PRODUCT_SEARCH") {
        return null
    }
    const targetMarket = result.targetMarket ? result.targetMarket.targetMarket : null
    const lang = i18n.language
    const translations = getTranslationsForLanguage(result.translations, lang, "fin", "eng")

    const enrichedAttributes = result.attributes.map((attr: any) => {
        if (props.attributeDetails) {
            const attrDetails = props.attributeDetails.get(attr.attributeId)
            if (attrDetails) {
                const attributeTranslations = getTranslationsForLanguage(attrDetails.translations, lang, "fi", "en")
                attr.attributeName = attributeTranslations ? attributeTranslations.name : "N/A"
            }
        }
        return attr
    })

    function getAccordionItem(id: string, title: string, content: any) {
        return <Collapsible key={id} header={title} open={openAllAccordions}>{content}</Collapsible>
    }

    const items: InstanceType<typeof Collapsible>[] = [
            getAccordionItem(
                "ai-allergens",
                showJsonFieldNames ? "allergens" : t("FullProduct.allergens._title"),
                translatedArrayToHtmlTable("allergens", result.allergens, lang, showJsonFieldNames, t),
            ),
            getAccordionItem(
                "ai-alternate-article-nos",
                showJsonFieldNames ? "alternateArticleNos" : t("FullProduct.alternateArticleNos._title"),
                arrayToHtmlTable("alternateArticleNos", result.alternateArticleNos, showJsonFieldNames, t),
            ),
            getAccordionItem(
                "ai-animal-feeding-langs",
                showJsonFieldNames ? "animalFeedingLangs" : t("FullProduct.animalFeedingLangs._title"),
                getAnimalFeedingLang(result.animalFeedingLangs, lang, showNullFields, showJsonFieldNames, t),
            ),
            getAccordionItem(
                "ai-attachments",
                showJsonFieldNames ? "attachments" : t("FullProduct.attachments._title"),
                getAttachmentTable("attachments", result.attachments, showJsonFieldNames, t),
            ),
            getAccordionItem(
                "ai-attributes",
                showJsonFieldNames ? "attributes" : t("FullProduct.attributes._title"),
                getAttributesTable("attributes", enrichedAttributes, showJsonFieldNames, t),
            ),
            getAccordionItem(
                "ai-diets",
                showJsonFieldNames ? "diets" : t("FullProduct.diets._title"),
                arrayToHtmlTable("diets", result.diets, showJsonFieldNames, t),
            ),
            getAccordionItem(
                "ai-eans",
                showJsonFieldNames ? "eans" : t("FullProduct.eans._title"),
                arrayToHtmlTable("eans", result.eans, showJsonFieldNames, t),
            ),
            getAccordionItem(
                "ai-health-related-langs",
                showJsonFieldNames ? "healthRelatedLangs" : t("FullProduct.healthRelatedLangs._title"),
                getHealthRelatedLang(result.healthRelatedLangs, lang, showNullFields, showJsonFieldNames, t),
            ),
            getAccordionItem(
                "ai-hierarchies",
                showJsonFieldNames ? "hierarchies" : t("FullProduct.hierarchies._title"),
                getHierarchiesTable("hierarchies", result.hierarchies, lang, showJsonFieldNames, t),
            ),
            getAccordionItem(
                "ai-ingredient-additives",
                showJsonFieldNames ? "ingredientAdditives" : t("FullProduct.ingredientAdditives._title"),
                translatedArrayToHtmlTable("ingredientAdditives", result.ingredientAdditives, lang, showJsonFieldNames, t),
            ),
            getAccordionItem(
                "ai-measurement",
                showJsonFieldNames ? "measurement" : t("FullProduct.measurement._title"),
                objectToHtmlTable("measurement", result.measurement, showNullFields, showJsonFieldNames, t, ["sokId"]),
            ),
            getAccordionItem(
                "ai-nutrients",
                showJsonFieldNames ? "nutrients" : t("FullProduct.nutrients._title"),
                arrayToHtmlTable("nutrients", result.nutrients, showJsonFieldNames, t),
            ),
            getAccordionItem(
                "ai-nutritional-claims",
                showJsonFieldNames ? "nutritionalClaims" : t("FullProduct.nutritionalClaims._title"),
                arrayToHtmlTable("nutritionalClaims", result.nutritionalClaims, showJsonFieldNames, t),
            ),
            getAccordionItem(
                "ai-nutrition-informations",
                showJsonFieldNames ? "nutritionInformations" : t("FullProduct.nutritionInformations._title"),
                arrayToHtmlTable("nutritionInformations", result.nutritionInformations, showJsonFieldNames, t),
            ),
            getAccordionItem(
                "ai-onix",
                showJsonFieldNames ? "onix" : t("FullProduct.onix._title"),
                (result.onix ? <pre>{ JSON.stringify(result.onix, null, 4) }</pre> : <p>N/A</p>),
            ),
            getAccordionItem(
                "ai-references",
                showJsonFieldNames ? "references" : t("FullProduct.references._title"),
                arrayToHtmlTable("references", result.references, showJsonFieldNames, t),
            ),
            getAccordionItem(
                "ai-regulatory-permits",
                showJsonFieldNames ? "regulatoryPermits" : t("FullProduct.regulatoryPermits._title"),
                arrayToHtmlTable("regulatoryPermits", result.regulatoryPermits, showJsonFieldNames, t),
            ),
            getAccordionItem(
                "ai-supplier-logisticals",
                showJsonFieldNames ? "supplierLogisticals" : t("FullProduct.supplierLogisticals._title"),
                arrayToHtmlTable("supplierLogisticals", result.supplierLogisticals, showJsonFieldNames, t),
            ),
            getAccordionItem(
                "ai-target-market",
                showJsonFieldNames ? "targetMarket" : t("FullProduct.targetMarket._title"),
                getTargetMarket(result.targetMarket, lang, showNullFields, showJsonFieldNames, t),
            ),
            getAccordionItem(
                "ai-translations",
                showJsonFieldNames ? "translations" : t("FullProduct.translations._title"),
                getAllTranslations(result.translations, lang, showNullFields, showJsonFieldNames, t),
            ),
            getAccordionItem(
                "ai-vak",
                showJsonFieldNames ? "vakData" : t("FullProduct.vakData._title"),
                objectToHtmlTable("vakData", result.vakData, showNullFields, showJsonFieldNames, t),
            ),
            getAccordionItem(
                "ai-vak-translation",
                showJsonFieldNames ? "vakDataTranslation" : t("FullProduct.translations._vak_title"),
                getAllTranslations(
                    result.vakData ? result.vakData.vakDataTranslation : undefined, 
                    lang, 
                    showNullFields, 
                    showJsonFieldNames, 
                    t
                ),
            ),
        ]
    
    // The accordion items do not update unless some of its props change. Changing of items had no effect so for
    // now the key is changed every time the showNullFields changes so that the changes are reflected to the
    // accordion items too.
    return <div>
        <h2>{getProductName(result, lang, targetMarket)}</h2>
        <p className={"full-product-ids"}>{result.sokId} {result.ean && " | " + result.ean}</p>
        { translations && <p className={"pre-wrap"}>{translations.marketingText}</p>}
        {objectToHtmlTable("", result, showNullFields, showJsonFieldNames, t)}
        <CollapsibleGroup accordion={false}>{items}</CollapsibleGroup>
    </div>
}

// SonarQube is complaining about some ternary operations, but with React they are pretty much mandatory when using TSX.
// The code could be refactored a bit in the future but the view is just for the MVP for now, so most likely this will
// get totally rewritten at some point.
const objectToHtmlTable = (keyPrefix: string, item: any, showNullFields: boolean, showJsonFieldNames: boolean, t: TFunction, skipTheseFields: string[] = ["onix"]) => { // NOSONAR
    if (!item) {
        return <p>N/A</p>
    }

    return <div className={"table-wrapper"}>
        <table>
            <tbody>
            {Object.keys(item).filter(key => !skipTheseFields.includes(key)).map(key => {
                if (typeof item[key] === "function" || (typeof item[key] === "object" && item[key] !== null && item[key] !== undefined)) {
                    return null
                }
                const undefOrNull = item[key] === undefined || item[key] == null;

                return <tr key={"full-product-field-" + key} className={undefOrNull && !showNullFields ? "hidden" : ""}>
                    <td>
                        <b>{showJsonFieldNames ? key : t("FullProduct." + (keyPrefix ? keyPrefix + "." : "") + key) /* NOSONAR*/}</b>
                    </td>
                    <td className={"pre-wrap"}>{undefOrNull ? "N/A" : item[key].toString()}</td>
                </tr>
            })}
            </tbody>
        </table>
    </div>
}

const arrayToHtmlTable = (keyPrefix: string, array: any, showJsonFieldNames: boolean, t: TFunction) => {
    if (!array || array.length === 0) {
        return <p>N/A</p>
    }
    let keys: string[] = []
    return <div className={"table-wrapper"}><table>
        <thead>
            <tr>
                {
                    // sokId is skipped in arrays as it is redundant information
                    Object.keys(array[0]).filter(key => key !== "sokId").map(key => {
                        keys.push(key)
                        return <th key={"full-product-" + keyPrefix + "-th-" + key}>
                            {showJsonFieldNames ? key : t(`FullProduct.${keyPrefix}.${key}`)}
                        </th>
                    })
                }
            </tr>
        </thead>
        <tbody>
        {array.map((item: any, index: number) => {
            return <tr key={"full-product-" + keyPrefix + "-" + index}>
                {keys.map(k =>
                    <td key={"full-product-" + keyPrefix + "-" + index + "-" + k} className={"pre-wrap"}>
                        {item[k] === undefined || item[k] === null ? "" : item[k].toString()}
                    </td>
                )}
            </tr>
        })}
        </tbody>
    </table></div>
}

const getAttachmentTable = (keyPrefix: string, array: any, showJsonFieldNames: boolean, t: TFunction) => {
    return (
        <div>
            {arrayToHtmlTable(keyPrefix, array, showJsonFieldNames, t)}
        </div>
    )
}

const getAttributesTable = (keyPrefix: string, array: any, showJsonFieldNames: boolean, t: TFunction) => {
    return (
        <div>
            {arrayToHtmlTable(keyPrefix, array, showJsonFieldNames, t)}
            <small>{t("FullProduct.enrichedByThisApp")}</small>
        </div>
    )
}

const getHierarchiesTable = (keyPrefix: string, array: any, lang: string, showJsonFieldNames: boolean, t: TFunction) => {
    return (
        <div>
            {translatedArrayToHtmlTable("hierarchies", array, lang, showJsonFieldNames, t)}
            {array && array.length > 0 && <small>{t("FullProduct.carbonFootprintNote")}</small>}
        </div>
    )
}

export const getProductName = (result: any, language: string, targetMarket?: string) => {
    // Try to find the English name if UI language is set to English. Fallback to Estonian if product's target market
    // is EE or to Finnish if it's anything else.
    const aapaLang = language === "en" ? "eng" : (targetMarket === "EE" ? "est" : "fin")
    let name: string | undefined = undefined
    try {
        const tmTranslations = result.targetMarket.targetMarketTranslations as Array<any>
        let requestedTranslation = tmTranslations.filter(translation => translation.language === aapaLang)
        // No translation found for the requested translation? Use Finnish.
        if (requestedTranslation.length === 0) {
            requestedTranslation = tmTranslations.filter(translation => translation.language === "fin")
        }
        name = requestedTranslation[0].productName
    } catch(e) {
        console.log("No targetMarketTranslations", e)
    }
    if (!name) {
        try {
            const trans = getTranslationsForLanguage(result.translations, language)
            name = trans.nameFull || trans.name40 || trans.name25
        } catch(e) {
            console.log("No translations", e)
        }
    }
    if (!name) {
        return language === "fi" ? "** Nimi puuttuu **" : "** Name not defined **"
    }
    return name
}

// Some translation arrays use 2-letter keys and some 3-letter ones, so you can specify the keys as parameters
const getTranslationsForLanguage = (translationArray: any, language: string, keyFinnish: string = "fin", keyEnglish: string = "eng") => {
    if (!translationArray || translationArray.length === 0) {
        return undefined
    }
    const aapaLang = language === "en" ? keyEnglish : keyFinnish
    try {
        const translations = translationArray as Array<any>
        let requestedTranslation = translations.filter(translation => translation.language === aapaLang)
        // No translation found for the requested translation? Use Finnish.
        if (requestedTranslation.length === 0) {
            requestedTranslation = translations.filter(translation => translation.language === keyFinnish)
        }
        return requestedTranslation[0]
    } catch(e) {
        console.log("No translations", e)
    }
    return undefined
}


const getAllTranslations = (translationArray: any, language: string, showNullFields: boolean, showJsonFieldNames: boolean, t: TFunction) => {
    if (!translationArray || translationArray.length === 0) {
        return <div>N/A</div>
    }
    return <div>
        {translationArray.map((trans: any) => {
            return <div key={"translation-" + trans.language}>
                <h2>{trans.language}</h2>
                {objectToHtmlTable("translations", trans, showNullFields, showJsonFieldNames, t)}
            </div>
        })}
    </div>
}

const translatedArrayToHtmlTable = (keyPrefix: string, array: any, language: string, showJsonFieldNames: boolean, t: TFunction) => {
    // Make a "flat" list of hierarchies with their translations
    const flatItems = array.map((h: any) => {
        // Make a copy of the array object so that fields don't get deleted from the original array
        const arrayObjectCopy = {...h}
        const translations = getTranslationsForLanguage(arrayObjectCopy.translations, language)
        const reorderedFieldsBeforeTranslations: any = {}
        const reorderedFieldsAfterTranslations: any = {}
        const reorderedFieldsAtTheEnd: any = {}
        if (keyPrefix === "hierarchies") {
            reorderedFieldsAfterTranslations.structureId = arrayObjectCopy.structureId
            reorderedFieldsAtTheEnd.carbonFootprint = arrayObjectCopy.carbonFootprint
            delete arrayObjectCopy.structureId
            delete arrayObjectCopy.carbonFootprint
        }
        const flat = { ...reorderedFieldsBeforeTranslations, ...translations, ...reorderedFieldsAfterTranslations, ...arrayObjectCopy, ...reorderedFieldsAtTheEnd }
        delete flat.translations
        delete flat.language
        return flat
    })
    return arrayToHtmlTable(keyPrefix, flatItems, showJsonFieldNames, t)
}

const getTargetMarket = (targetMarket: any, language: string, showNullFields: boolean,  showJsonFieldNames: boolean, t: TFunction) => {
    if (!targetMarket) {
        return "N/A"
    }
    const tmTranslations = getTranslationsForLanguage(targetMarket.targetMarketTranslations, language)
    const tmFlat = { ...tmTranslations, ...targetMarket }
    delete tmFlat.targetMarketTranslations
    delete tmFlat.language
    return objectToHtmlTable("targetMarket", tmFlat, showNullFields, showJsonFieldNames, t)
}

const getAnimalFeedingLang = (animalFeedingLang: any, language: string, showNullFields: boolean,  showJsonFieldNames: boolean, t: TFunction) => {
    const translations = getTranslationsForLanguage(animalFeedingLang, language)

    return objectToHtmlTable("animalFeedingLangs", translations, showNullFields, showJsonFieldNames, t, ["language", "sokId"])
}

const getHealthRelatedLang = (healthRelatedLang: any, language: string, showNullFields: boolean, showJsonFieldNames: boolean, t: TFunction) => {
    const translations = getTranslationsForLanguage(healthRelatedLang, language)
    return objectToHtmlTable("healthRelatedLangs", translations, showNullFields, showJsonFieldNames, t, ["language", "sokId"])
}
